import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"
import { CourseInstructorProfileGiorgioBoa } from "../components/course/course-instructor-profile/CourseInstructorProfileGiorgioBoa"
import { CourseInstructorProfile } from "../components/course/course-instructor-profile/CourseInstructorProfile"

export default class CorsoJavascriptTypescriptIntro extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query { 
            git_fundamentals: file(relativePath: { eq: "courses/courses-logos/git-fundamentals.png" }) { ...thumb },
            javascript_typescript: file(relativePath: { eq: "courses/courses-logos/typescript.png" }) { ...thumb },

            review_javascript_1: file(relativePath: { eq: "courses/courses-reviews/javascript/review_javascript_1.png" }) { ...imgTrainingBig },
            review_javascript_2: file(relativePath: { eq: "courses/courses-reviews/javascript/review_javascript_2.png" }) { ...imgTrainingBig },
            review_javascript_10: file(relativePath: { eq: "courses/courses-reviews/javascript/review_javascript_10.png" }) { ...imgTrainingBig },

            mode1: file(relativePath: { eq: "courses/courses-mode-images/github-beginners-mode2.png" }) { ...imgMd },
            mode3: file(relativePath: { eq: "courses/courses-mode-images/github-beginners-mode1.png" }) { ...imgMd },
            pricing_bg: file(relativePath: { eq: "courses/pricing/javascript.png" }) { ...imgMd },

          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#f35932',
    separatorHeaderColor2: '#000',
    separatorColor1: '#e58067',
    separatorColor2: '#f35932',
    buttonColor: '#000',
    panelBackground1: '#f35932',
    iconColor1: '#f35932',       // summary
    iconColor2: '#f35932',    // faq
    footerVisible: true,
    navbarVisible: true,
  },
  instructors: [
    CourseInstructorProfileGiorgioBoa,
    CourseInstructorProfile
  ],
  header: {
    titleSEO: 'Corso Git e GitHub per principianti',
    title: 'Corso Git per developers and teams',
    shortTitle: 'GIT & GITHUB<br>FUNDAMENTALS',
    subtitle: 'Un corso su git e github per acquisire le fondamenta che ogni sviluppatore dovrebbe conoscere sul più diffuso sistema di versionamento!',
    image: 'git_fundamentals',
    difficulty: null,
    duration: null
  },
  description: [
    { title: 'INTRODUZIONE', text: 'In questo corso tratteremo gli elementi essenziali di Git, il sistema di versionamento più efficace e diffuso. Sarai in grado di creare un nuovo repository Git, eseguire il commit delle modifiche e rivedere la cronologia del commit di un repository esistente. Imparerai a definire una metodologia di lavoro in team per portare avanti i nuovi sviluppi e distribuire in produzione il tuo progetto. Scenderemo nel dettaglio della gestione dei branch e dello sviluppo collaborativo in team con attenzione alla fase di revisione tramite Pull Request.'},
    /*{ title: 'INTRODUZIONE', text: 'Una corso su Javascript ES6/7/8 e un\'introduzione sulle feature di Typescript che ogni sviluppatore dovrebbe conoscere per sfruttare al meglio framework e librerie Javascript di ultima generazione come Angular, React, Vue, Svelte ...'},*/
    { title: 'OBIETTIVO', text: `
    Al termine del corso i partecipanti saranno in grado di:<br>

•\tdefinire una metodologia di lavoro collaborativo in team<br>
•\tcreare un nuovo repository GIT<br>
•\teseguire commit del codice sviluppato<br>
•\trivedere la cronologia delle varie modifiche<br>
•\tlavorare con i branch<br>
•\teseguire il merge nel progetto di vari sviluppi paralleli<br>
•\tcreare pull request per le modifiche al progetto<br>
•\tpadroneggiare il processo di revisone del codice<br>
•\tgestire approvazioni e modifiche di una pull request<br>
•\tmonitorare lo stato del repository tramite GitHub<br>
•\tcreazione di una Single Page Application in React<br>
•\tcapiremo come usare build - lint - test<br>
•\timpareremo ad eseguire la distribuzione dell’applicazione<br>

    `},
    { title: 'REQUISITI', text: 'Conoscere un qualsiasi linguaggio di programmazione'},
    { title: 'A CHI È RIVOLTO', text: 'Il corso è rivolto a tutti gli sviluppatori che vogliono approcciarsi al versionamento del codice e imparare a lavorare in team distribuiti.'},
  ],
  metadata: [
  ],
  carousels: {
    main: null,
    reviews: null,
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '8 ore (1g)' },
    { icon: faChartBar, label: 'Livello:', value: 'Principiante' },
    // { icon: faCode, label: 'Versione Javascript:', value: '6/7/8' },
    // { icon: faCode, label: 'Versione Typescript:', value: '3.9+' },
    { icon: faUser, label: 'Tipologia:', value: 'in aula o da remoto' },
  ],
  exampleLesson: {
    enabled: false,
    title: 'ESEMPIO LEZIONE',
    desc: 'In questa breve video, esamineremo le :',
    video1: 'Il8AQFkY4iE',
    bgColor: '#ddb657',
    items: [
      { text: '"var" vs "let"'},
      { text: 'Block scope'},
      { text: 'Function scope'},
    ]
  },
  mode: {
    colLeft: {
      img: 'mode3',
      title: 'TEORIA',
      text: 'Sessioni di teoria per l\'apprendimento degli argomenti affrontati durante il corso ed esercitazioni pratiche eseguite dal/dai docenti per la simulazione di scenari e casi d\'uso reali '
    },
    colCenter: null,
    colRight: {
      img: 'mode1',
      title: 'ESERCITAZIONI STUDENTI',
      text: 'Ogni argomento include delle esercitazioni pratiche che i partecipanti potranno svolgere durante (e dopo) il corso allo scopo di consolidare i concetti acquisiti',
    }
  },
  program: {
    colLeft: [
      { type: 'title', value: '1. Introduzione a GIT '},
      { value: 'In questa prima fase andremo ad installare GIT sulla macchina locale, creando il primo repository remoto atto a ricevere il nostro codice. Sincronizzeremo poi il repository remoto con cartella locale del nostro pc.'},
      { value: '•	verifica/installazione GIT in locale (Win/MacOS/Linux)'},
      { value: '•	setup progetto su GitHub (o su eventuale altro repository utilizzato aziendalmente)'},
      { value: '•	download progetto in locale per iniziare gli sviluppi'},

      { type: 'title', value: '2. Apprendimento comandi base propedeutici al corso '},
      { value: 'In questa fase andremo ad imparare la maggior parte dei comandi base di  GIT. \n' +
          'Grazie a questo passaggio saremo poi in grado di apprendere al meglio come lavorare in team e versionare il nostro progetto.\n'},
      { value: '•	inizializzazione repository GIT'},
      { value: '•	allineamento modifiche GIT al codice del progetto'},
      { value: '•	versionamento del codice tramite comandi GIT add/git commit'},
      { value: '•	modifica di commit parziali e/o errati'},
      { value: '•	sviluppo funzionalità in parallelo ad altri membri del team'},
      { value: '•	merge degli sviluppi paralleli'},


      { type: 'title', value: '3. Creazione progetto Javascript '},
      { value: 'In questa fase andremo a creare un progetto JavaScript ES6 che sarà utilizzato nei capitoli successivi per simulare la gestione di un progetto in un team di sviluppatori'},
      { value: '• creazione progetto JavaScript ES6'},

      { type: 'title', value: '4. Iniziamo a versionare il codice '},
      { value: 'Utilizzando il repository locale precedentemente creato andremo a eseguire i primi commit.'},
      { value: '•	creazione prima funzionalità'},
      { value: '•	esecuzione del primo commit'},
      { value: '•	modificare un commit già eseguito'},
      { value: '•	sincronizzazione del repository locale con remoto'},
      { value: '•	verifica nel repository remoto del lavoro precedentemente svolto'},


    ],
    colRight: [
      { type: 'title', value: '5. Creare il  primo branch '},
      { value: 'Iniziamo lo sviluppo di una nuova funzionalità e ci avvaliamo del comando branch per gestire al meglio il nostro repository. Impareremo a monitorare e gestire la “storia” di un repository GIT e a verificare eventuali differenze con gli altri branch.'},
      { value: '•	creazione nuovo branch per sviluppare una nuova funzionalità'},
      { value: '•	esecuzione merge del codice nel ramo principale master'},
      { value: '•	impareremo a gestire eventuali conflitti in fase di merge'},


     { type: 'title', value: '6. Lavorare in team con GIT '},
      { value: 'In questo capitolo vedremo come condividere il progetto all\'interno del team simulando delle modifiche collaborative.'},
      { value: '•	creazione nuovo branch per sviluppare una nuova funzionalità'},
      { value: '•	sviluppo della nuova funzionalità'},
      { value: '•	sincronizzazione del codice con il repository remoto'},

     { type: 'title', value: '7. Creiamo la nostra prima Pull Request (PR) '},
      { value: 'In questo capitolo vedremo come creare la nostra prima pull request che darà atto poi alla modifica del codice del progetto. \n' +
          'Capiremo poi l’importanza di una revisione delle modifiche che entrano nel progetto e vedremo come padroneggiare al meglio questo processo.<br> In questo capitolo andremo a simulare il lavoro collaborativo tra team mirato alla correttezza e pulizia del codice tramite processo di code review.\n'},
      { value: '•	creazione nuovo branch per sviluppare una nuova funzionalità'},
      { value: '•	configurare il repository per abilitare il processo di code review'},
      { value: '•	invio della pull request al processo di code review'},
      { value: '•	eseguire una code review'},
      { value: '•	approvazione della PR'},
      { value: '•	richiedere la modifica di una PR'},
      { value: '•	effettuare la modifica di una PR'},


     { type: 'title', value: 'Esercitazioni pratiche'},
      { value: 'Ogni capitolo è accompagnato da esercitazioni pratiche svolte dagli studenti allo scopo di assimilare tutti i concetti descritti durante il corso.'},


/*
      { type: 'title', value: 'BONUS: DOM & CSS'},
      { value: 'Manipolazione DOM' },
      { value: 'CSS Selectors' },
      { value: 'Creazione Griglie e Layout con Flexbox' },
      { value: 'Introduzione a CSS Grid per la creazione di layout' },
      { value: 'Utilizzo di framework CSS come Bootstrap' },*/
    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione', extra: '8 ore (1g)'},
      { label: 'Dispense', extra: 'PDF formato A4'},
      { label: 'Esercitazioni', extra: 'GitBook'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI INFO',
      target: '_self',
      scrollToID: ''
    },
    bg: 'pricing_bg',
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'

    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
  videos: {
    teaser1: null,
    teaser1Text: ` `
  },

}
